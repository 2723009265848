import { useId } from 'react';

import classNames from 'classnames';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const StoreMainInlineCarouselBanner = ({
  dataKey,
  className,
  userId,
}: {
  dataKey?: string;
  className: string;
  userId?: number;
}) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div className={className} id={id}>
      <ins
        className={classNames(ADS_CONSTANTS.className)}
        data-cp-user-id={userId}
        data-key={dataKey}
      ></ins>
    </div>
  );
};

export default StoreMainInlineCarouselBanner;
